import React from 'react';
import softwareImage1 from '../assets/images/1010.png';
import softwareImage2 from '../assets/images/666.png';
import './Software.css';

const Software = () => {
    return (
        <section className="software-section">
            <div className="title-container">
                <div className="line"></div>
                <h1 className="title">Программное обеспечение "ОКПС-Контроль"</h1>
                <div className="line"></div>
            </div>
            <div className="content">
                <div className="left-column">
                    <img src={softwareImage1} alt="ОКПС-Контроль 1" className="image" />
                    <img src={softwareImage2} alt="ОКПС-Контроль 2" className="image" />
                </div>
                <div className="right-column">
                    <h2 className="subtitle">- Полный контроль процесса стрельбы</h2>
                    <ul className="list">
                        <li>Стрельба возможна исключтельно по команде инструктора</li>
                        <li>Инструктор выбирает стрельковую позицию и режим стрельбы</li>
                    </ul>
                    <h2 className="subtitle">- Настройка параметров стрельбы</h2>
                    <ul className="list">
                        <li>Инструктор определяет количество режимов стрельбы и их параметры</li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Software;
