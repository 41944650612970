import React from 'react';
import Hero from '../components/Hero';
import About from '../components/About';
import Products from '../components/Products';
import PrincipleOfOperation from '../components/PrincipleOfOperation';
import KeyElements from '../components/KeyElements';
import KeyElements2 from '../components/KeyElements2';
import OtherElements from '../components/OtherElements';
import Software from '../components/Software';
import Software2 from '../components/Software2';
import Safety from '../components/Safety';
import Usage from '../components/Usage';
import Contacts from '../components/Contacts';
import Header from '../components/Header';
import './Home.css'; // Подключение нового файла стилей

const Home = () => {
    return (
        <>
            <Header />
            <section id="about" className="section">
                <About />
            </section>
            <section id="products" className="section">
                <Products />
            </section>
            <section id="principle-of-operation" className="section">
                <PrincipleOfOperation />
            </section>
            <section id="key-elements" className="section">
                <KeyElements />
            </section>
            <section id="key-elements-2" className="section">
                <KeyElements2 />
            </section>
            <section id="other-elements" className="section">
                <OtherElements />
            </section>
            <section id="software" className="section">
                <Software />
            </section>
            <section id="software-2" className="section">
                <Software2 />
            </section>
            <section id="safety" className="section">
                <Safety />
            </section>
            <section id="usage" className="section">
                <Usage />
            </section>
            <section id="contacts" className="section">
                <Contacts />
            </section>
        </>
    );
};

export default Home;
