// src/App.js
import React, { useEffect, useState } from 'react';
import Home from './pages/Home';
import Header from './components/Header';
import './index.css';

function App() {
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);

    useEffect(() => {
        let lastScrollTop = 0;

        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop > lastScrollTop && scrollTop > 100) {
                setIsHeaderVisible(false);
            } else {
                setIsHeaderVisible(true);
            }
            lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
        };

        const handleMouseEnter = () => {
            setIsHeaderVisible(true);
        };

        window.addEventListener('scroll', handleScroll);
        const header = document.getElementById('header');
        if (header) {
            header.addEventListener('mouseenter', handleMouseEnter);
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (header) {
                header.removeEventListener('mouseenter', handleMouseEnter);
            }
        };
    }, []);

    return (
        <div>
            <Header isVisible={isHeaderVisible} />
            <Home />
        </div>
    );
}

export default App;
