// src/components/Hero.jsx
import React from 'react';
import { motion } from 'framer-motion';
import backgroundImage from '../assets/images/hero-image.jpg';

const Hero = () => {
    return (
        <motion.section
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            style={styles.hero}
        >
            <div style={styles.overlay}>
                <h1 style={styles.title}>
                </h1>
            </div>
        </motion.section>
    );
};

const styles = {
    hero: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        padding: '0 20px',
        boxSizing: 'border-box',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    title: {
        color: '#fff',
        fontSize: '3rem',
        maxWidth: '800px',
    },
    '@media (max-width: 1200px)': {
        title: {
            fontSize: '2.5rem',
        }
    },
    '@media (max-width: 768px)': {
        title: {
            fontSize: '2rem',
        }
    },
    '@media (max-width: 480px)': {
        title: {
            fontSize: '1.5rem',
        }
    }
};

export default Hero;
