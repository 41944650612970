import React from 'react';
import productImage from '../assets/images/ОКПС-ЗЕВС-3-3-1.png';
import './Products.css';

const Products = () => {
    return (
        <section className="products-section">
            <div className="title-container">
                <div className="line"></div>
                <h1 className="title">Продукция</h1>
                <div className="line"></div>
            </div>
            <h2 className="subtitle">Комплекс "ЗЕВС-3" на 2 или 6* стрелковых позиций</h2>
            <img src={productImage} alt="Комплекс ЗЕВС-3" className="product-image"/>
            <ul className="list">
                <li className="list-item">Разработанный совместно с профессионалами в области практической стрельбы,</li>
                <li className="list-item">делает процесс обучения наиболее качественным, безопасным и приближенным к реальности</li>
            </ul>
            <p className="note">*цена уточняется по запросу</p>
        </section>
    );
};

export default Products;
