import React from 'react';
import targetImage from '../assets/images/Мишенная-уст.png';
import rifleImage from '../assets/images/АК47.png';
import './KeyElements.css';

const KeyElements = () => {
    return (
        <section className="key-elements-section">
            <div className="title-container">
                <div className="line"></div>
                <h1 className="title">Ключевые элементы комплекса</h1>
                <div className="line"></div>
            </div>
            <div className="elements-container">
                <div className="element">
                    <img src={targetImage} alt="Мишенная установка" className="image" />
                    <p className="caption">Мишенная установка с функцией дистанционного управления (2/6* шт.)</p>
                </div>
                <div className="element">
                    <img src={rifleImage} alt="Модифицированная модель автомата АК-74" className="image" />
                    <p className="caption">Модифицированная массогабаритная модель автомата АК-74, стреляющая пластиковыми пулями (2/6* шт.)</p>
                </div>
            </div>
        </section>
    );
};

export default KeyElements;
