import React from 'react';
import principleImage from '../assets/images/Комплекс-ЗЕВС3-2048x1180.png';
import './PrincipleOfOperation.css';

const PrincipleOfOperation = () => {
    return (
        <section className="principle-section">
            <div className="title-container">
                <div className="line"></div>
                <h1 className="title">Принцип работы</h1>
                <div className="line"></div>
            </div>
            <img src={principleImage} alt="Принцип работы" className="principle-image" />
        </section>
    );
};

export default PrincipleOfOperation;
