import React from 'react';
import './About.css';

const About = () => {
    return (
        <section className="about-section">
            <div className="title-container">
                <div className="line1"></div>
                <h1 className="title1">О НАС</h1>
                <div className="line1"></div>
            </div>
            <div className="text-container">
                <p className="subtitle1">Акционерное общество «Фирма СТ» занимается разработкой, производством и внедрением надежного отечественного оборудования для безопасного и эффективного обучения практической стрельбе.</p>
                <p className="subtitle1">Представленная на сайте продукция создавалась нашим отделом разработки совместно с экспертами в области практической стрельбы и не имеет аналогов на рынке.</p>
                <p className="subtitle1">Предлагаемые нами решения делают процесс обучения практической стрельбе наиболее безопасным и приближенным к реальности.</p>
            </div>
        </section>
    );
};

export default About;
