import React from 'react';
import controlBlockImage from '../assets/images/Блок-управления-компл.png';
import laptopImage from '../assets/images/Ноутбук.png';
import './KeyElements2.css';

const KeyElements2 = () => {
    return (
        <section className="key-elements2-section">
            <div className="title-container">
                <div className="line"></div>
                <h1 className="title">Ключевые элементы комплекса</h1>
                <div className="line"></div>
            </div>
            <div className="elements-container">
                <div className="element">
                    <img src={laptopImage} alt="Ноутбук с ПО" className="image" />
                    <p className="caption">Ноутбук с установленным ПО "ОКПС-Контроль"</p>
                </div>
                <div className="element">
                    <img src={controlBlockImage} alt="Блок управления" className="image" />
                    <p className="caption">Блок управления комплексом "ЗЕВС-3"</p>
                </div>
            </div>
        </section>
    );
};

export default KeyElements2;
