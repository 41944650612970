import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import logo from '../assets/images/OKPS-red.png';
import './Header.css';

const Header = ({ isVisible }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <motion.header
            id="header"
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: isVisible ? 0 : -100, opacity: isVisible ? 1 : 0 }}
            transition={{ duration: 0.5 }}
            className="header"
        >
            <div className="logo-container">
                <img src={logo} alt="OKPS Logo" className="logo-image" />
                <div className="logo-text">Обучающие Комплексы Практической Стрельбы</div>
                <button className="burger-button" onClick={toggleMenu}>
                    ☰
                </button>
            </div>
            <nav className="nav-desktop">
                <a href="#about" className="link">О нас</a>
                <a href="#products" className="link">Продукция</a>
                <a href="#safety" className="link">Безопасность</a>
                <a href="#usage" className="link">Эксплуатация</a>
                <a href="#contacts" className="link">Контакты</a>
            </nav>
            <AnimatePresence>
                {isMenuOpen && (
                    <motion.nav
                        className="nav-mobile"
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <a href="#about" className="link" onClick={toggleMenu}>О нас</a>
                        <a href="#products" className="link" onClick={toggleMenu}>Продукция</a>
                        <a href="#safety" className="link" onClick={toggleMenu}>Безопасность</a>
                        <a href="#usage" className="link" onClick={toggleMenu}>Эксплуатация</a>
                        <a href="#contacts" className="link" onClick={toggleMenu}>Контакты</a>
                    </motion.nav>
                )}
            </AnimatePresence>
        </motion.header>
    );
};

export default Header;
