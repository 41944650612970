// src/components/OtherElements.jsx
import React from 'react';
import logo from '../assets/images/OKPS-red.png';

const OtherElements = () => {
    return (
        <section style={styles.section}>
            <div style={styles.titleContainer}>
                <div style={styles.line}></div>
                <h1 style={styles.title}>Прочие элементы комплекса</h1>
                <div style={styles.line}></div>
            </div>
            <div style={styles.content}>
                <ul style={styles.list}>
                    <li style={styles.listItem}>- Инструкция по экмплуатации стрелкового тренажера ОКПС ЗЕВС-3</li>
                    <li style={styles.listItem}>- Противорикошетное панно с функцией пулеулавливателя (1/2* шт.)</li>
                    <li style={styles.listItem}>- Пульт дистанционного управления мишенными установками</li>
                    <li style={styles.listItem}>- Подставка для мишенной установки (2/6* шт)</li>
                    <li style={styles.listItem}>- Система воспроизведения звуков выстрелов</li>
                    <li style={styles.listItem}>- Комплект защитных очков (30/30* шт)</li>
                    <li style={styles.listItem}>- Комплект пластиковых шаров массой 0.2 г (20/60* тыс. шт.)</li>
                    <li style={styles.listItem}>- Устройства, облегчающие заряжание магазина (2/6* шт.)</li>
                    <li style={styles.listItem}>- Тактический ковёр для стрельбы из положения лёжа (2/6* шт.)</li>
                    <li style={styles.listItem}>- Разграничитель линии огня</li>
                    <li style={styles.listItem}>- Периферийные устройства для подключения оборудования</li>
                </ul>
                <div style={styles.logoContainer}>
                    <img src={logo} alt="OKPS Logo" style={styles.logo} />
                </div>
            </div>
        </section>
    );
};

const styles = {
    section: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '2rem',
        backgroundColor: '#2f3e56',
        color: '#fff',
        minHeight: '100vh',
        boxSizing: 'border-box',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2rem',
        width: '100%',
        justifyContent: 'center',
    },
    title: {
        fontSize: '2.5rem',
        fontWeight: '300',
        fontFamily: 'Roboto, sans-serif',
        textAlign: 'center',
        margin: '0 1rem',
        color: '#fff',
    },
    line: {
        flex: 1,
        height: '2px',
        backgroundColor: '#fff',
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
    },
    list: {
        listStyleType: 'none',
        padding: '0',
        margin: '0',
        maxWidth: '60%',
        width: '100%',
    },
    listItem: {
        fontSize: '1.4rem',
        marginBottom: '1rem',
        color: '#fff',
        fontWeight: '200',
        fontFamily: 'NTSomic',
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '40%',
        width: '80%',
    },
    logo: {
        maxWidth: '80%',
        height: 'auto',
    },
    '@media (max-width: 1200px)': {
        title: {
            fontSize: '3rem',
        },
        listItem: {
            fontSize: '1.5rem',
        },
        content: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        list: {
            maxWidth: '100%',
        },
        logoContainer: {
            maxWidth: '100%',
            marginTop: '2rem',
        },
    },
    '@media (max-width: 768px)': {
        title: {
            fontSize: '2.5rem',
        },
        listItem: {
            fontSize: '1.3rem',
        },
    },
    '@media (max-width: 480px)': {
        title: {
            fontSize: '2rem',
        },
        listItem: {
            fontSize: '1.1rem',
        },
    },
};

export default OtherElements;
