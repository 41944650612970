import React from 'react';
import reportImage from '../assets/images/999.png';
import './Software2.css';

const Software2 = () => {
    return (
        <section className="software2-section">
            <div className="title-container">
                <div className="line"></div>
                <h1 className="title">Сбор и анализ статистических данных о процессе стрельбы</h1>
                <div className="line"></div>
            </div>
            <div className="content">
                <div className="left-column">
                    <h2 className="subtitle"></h2>
                    <h2 className="subtitle">Сводный отчет</h2>
                    <ul className="list">
                        <li>Информация о частоте использования оборудования</li>
                        <li>Учет пластиковых пуль (Своевременное пополнение запаса пластиковых пуль)</li>
                    </ul>
                </div>
                <img src={reportImage} alt="Сводный отчет" className="image" />
            </div>
        </section>
    );
};

export default Software2;
