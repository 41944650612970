import React from 'react';

// Импортируйте изображения
import addressIcon from '../assets/images/address.png';
import phoneIcon from '../assets/images/phone.png';
import emailIcon from '../assets/images/email.png';

const Contacts = () => {
    return (
        <section style={styles.section} id="contacts">
            <div style={styles.titleContainer}>
                <div style={styles.line}></div>
                <h1 style={styles.title}>Контакты</h1>
                <div style={styles.line}></div>
            </div>
            <div style={styles.content}>
                <p style={styles.companyName}>АО “Фирма СТ”</p>
                <div style={styles.contactItem}>
                    <img src={addressIcon} alt="Address" style={styles.icon} />
                    <div>
                        <h2 style={styles.contactTitle}>Адрес</h2>
                        <p>119415, г. Москва, просп. Вернадского, 41, стр. 1, офис 931</p>
                    </div>
                </div>
                <div style={styles.contactItem}>
                    <img src={phoneIcon} alt="Phone" style={styles.icon} />
                    <div>
                        <h2 style={styles.contactTitle}>Телефон</h2>
                        <p>+7 (925) 169 37-72</p>
                    </div>
                </div>
                <div style={styles.contactItem}>
                    <img src={emailIcon} alt="Email" style={styles.icon} />
                    <div>
                        <h2 style={styles.contactTitle}>E-mail</h2>
                        <p>info@aofst.org</p>
                    </div>
                </div>
            </div>
            <footer style={styles.footer}>
                <div style={styles.footerLinks}>
                    <a href="#about" style={styles.footerLink}>О нас</a>
                    <a href="#products" style={styles.footerLink}>Продукция</a>
                    <a href="#safety" style={styles.footerLink}>Безопасность</a>
                    <a href="#usage" style={styles.footerLink}>Эксплуатация</a>
                    <a href="#contacts" style={styles.footerLink}>Контакты</a>
                </div>
                <div style={styles.footerContactInfo}>
                    <div style={styles.footerAddress}>
                        119415, г. Москва, просп. Вернадского, 41, стр. 1, офис 931
                    </div>
                    <div style={styles.footerPhoneEmail}>
                        <div>+7 (925) 169 37-72</div>
                        <div>info@aofst.org</div>
                    </div>
                </div>
            </footer>
        </section>
    );
};

const styles = {
    section: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '2rem',
        backgroundColor: '#f5f5f5',
        backgroundImage: 'linear-gradient(135deg, #ffffff 25%, #f5f5f5 25%, #f5f5f5 50%, #ffffff 50%, #ffffff 75%, #f5f5f5 75%, #f5f5f5)',
        backgroundSize: '20px 20px',
        color: '#000',
        minHeight: '100vh',
        boxSizing: 'border-box',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2rem',
        width: '100%',
        justifyContent: 'center',
    },
    title: {
        fontSize: '2.5rem',
        fontWeight: '200',
        fontFamily: 'NTSomic',
        textAlign: 'center',
        margin: '0 1rem',
        color: '#000',
    },
    line: {
        flex: 1,
        height: '2px',
        backgroundColor: '#000',
    },
    content: {
        width: '100%',
        maxWidth: '800px',
        textAlign: 'left',
    },
    companyName: {
        fontSize: '1.5rem',
        marginBottom: '2rem',
        textAlign: 'center',
        fontWeight: '200',
        fontFamily: 'NTSomic',
    },
    contactItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1.5rem',
        fontWeight: '200',
        fontFamily: 'NTSomic',
    },
    icon: {
        width: '30px',
        height: '30px',
        marginRight: '1rem',
    },
    contactTitle: {
        fontSize: '1.5rem',
        fontWeight: '500',
    },
    footer: {
        marginTop: '4rem',
        width: '100%',
        backgroundColor: '#3f51b5',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2rem',
    },
    footerLinks: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1rem',
        flexWrap: 'wrap',
    },
    footerLink: {
        color: '#fff',
        fontSize: '1.5rem',
        margin: '0 1rem',
        textDecoration: 'none',
    },
    footerContactInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '800px',
        borderTop: '1px solid #fff',
        paddingTop: '1rem',
        marginTop: '1rem',flexWrap: 'wrap',
    },
    footerAddress: {
        fontSize: '1rem',
        textAlign: 'left',
        flex: '1 1 40%',
    },
    footerPhoneEmail: {
        fontSize: '1rem',
        textAlign: 'right',
        flex: '1 1 40%',
    },
    '@media (max-width: 768px)': {
        title: {
            fontSize: '3rem',
        },
        companyName: {
            fontSize: '1.4rem',
        },
        contactTitle: {
            fontSize: '1.4rem',
        },
        icon: {
            fontSize: '1.5rem',
        },
        footerLink: {
            fontSize: '1.4rem',
        },
        footerAddress: {
            fontSize: '0.9rem',
        },
        footerPhoneEmail: {
            fontSize: '0.9rem',
        },
    },
};

export default Contacts;