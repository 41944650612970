// src/components/Usage.jsx
import React from 'react';

const Usage = () => {
    return (
        <section style={styles.section}>
            <div style={styles.titleContainer}>
                <div style={styles.line}></div>
                <h1 style={styles.title}>Эксплуатация</h1>
                <div style={styles.line}></div>
            </div>
            <div style={styles.content}>
                <div style={styles.borderedParagraph}>
                    Комплекс “ЗЕВС-3” отличается исключительной надежностью и простотой в использовании
                </div>
                <div style={styles.subsection}>
                    <h2 style={styles.subtitle}>
                        <span style={styles.arrow}>&gt;</span> Надежность
                    </h2>
                    <ul style={styles.list}>
                        <li style={styles.listItem}>- Оборудование на 90% состоит из отечественных комплектующих</li>
                        <li style={styles.listItem}>- Все элементы комплекса “ЗЕВС-3” прошли проверку временем и доказали свою безотказность</li>
                    </ul>
                </div>
                <div style={styles.subsection}>
                    <h2 style={styles.subtitle}>
                        <span style={styles.arrow}>&gt;</span> Простота в установке и эксплуатации
                    </h2>
                    <ul style={styles.list}>
                        <li style={styles.listItem}>- Установка комплекса “ЗЕВС-3” занимает не более 15 минут. Справиться с ней под силу каждому</li>
                        <li style={styles.listItem}>- Оборудование не требует дополнительных настроек и готово к работе сразу после установки</li>
                        <li style={styles.listItem}>- Для работы с комплексом “ЗЕВС-3” не требуются специальные навыки. Программа “Контроль” проста в использовании и интуитивно понятна</li>
                        <li style={styles.listItem}>- Комплект поставки содержит подробную инструкцию по установке и использованию оборудования</li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

const styles = {
    section: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '2rem',
        backgroundColor: '#3f51b5',
        color: '#fff',
        minHeight: '100vh',
        boxSizing: 'border-box',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2rem',
        width: '100%',
        justifyContent: 'center',
    },
    title: {
        fontSize: '2.5rem',
        fontWeight: '300',
        fontFamily: 'Roboto, sans-serif',
        textAlign: 'center',
        margin: '0 1rem',
        color: '#fff',
    },
    line: {
        flex: 1,
        height: '2px',
        backgroundColor: '#fff',
    },
    content: {
        width: '100%',
    },
    borderedParagraph: {
        fontSize: '1.4rem',
        backgroundColor: '#fff',
        color: '#000',
        fontWeight: '300',
        fontFamily: 'NTSomic',
        marginBottom: '2rem',
        textAlign: 'center',
        border: '0px solid #e53935',
        padding: '1rem',
        borderRadius: '8px',
        maxWidth: '20000px',
        margin: '0 auto 2rem auto',
    },
    subsection: {
        marginBottom: '2rem',
    },
    subtitle: {
        fontSize: '1.5rem',
        fontWeight: '300',
        fontFamily: 'NTSomic',
        display: 'flex',
        color: '#fff',
        alignItems: 'center',
        marginBottom: '1rem',
    },
    arrow: {
        color: '#e53935',
        marginRight: '0.5rem',
    },
    list: {
        listStyleType: 'none',
        padding: '0',
    },
    listItem: {
        fontSize: '1.4rem',
        fontWeight: '200',
        color: '#d1cfcf',
        fontFamily: 'NTSomic',
        marginBottom: '0.5rem',
    },
    '@media (max-width: 1200px)': {
        title: {
            fontSize: '3rem',
        },
        borderedParagraph: {
            fontSize: '1.4rem',
        },
        subtitle: {
            fontSize: '1.5rem',
        },
        listItem: {
            fontSize: '1.4rem',
        },
    },
    '@media (max-width: 768px)': {
        title: {
            fontSize: '2.5rem',
        },
        borderedParagraph: {
            fontSize: '1rem',
        },
        subtitle: {
            fontSize: '1.3rem',
        },
        listItem: {
            fontSize: '1rem',
        },
    },
    '@media (max-width: 480px)': {
        title: {
            fontSize: '2rem',
        },
        borderedParagraph: {
            fontSize: '0.9rem',
        },
        subtitle: {
            fontSize: '1.1rem',
        },
        listItem: {
            fontSize: '0.9rem',
        },
    },
};

export default Usage;
